





























import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import Scaffold from '@/components/Presenter/Scaffold.vue';
import SessionCard from '@/components/Presenter/SessionCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  components: { Scaffold, SessionCard },
  data() {
    return {
      showAccountMenu: false,
      buttons: [
        {
          label: this.$t('views.presenter.homePlanSession'),
          onClick: () => {
            this.$router.push({ name: 'New Tour' });
          },
        },
      ],
    };
  },
  async mounted() {
    const userId = await this.$store.getters['auth/getUserId'];
    await this.$store.dispatch('presenter/bindPastSessionsRef', userId);
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      sessions: 'presenter/getPastSessions',
    }),
  },
  methods: {
    ...mapMutations({
      reset: 'presenter/RESET',
    }),
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
    goToSessionDetail(session: any): void {
      this.$router.push({
        name: 'Presenter Session Stats',
        params: { presenterSessionId: session.shareToken, session },
      });
    },
  },
  beforeDestroy() {
    this.reset();
  },
});
